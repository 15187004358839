import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout isShowNav={false}>
        <SEO title="404: Nie znaleziono" />
        <h1>Nie ma takiej storny</h1>
        <p>Przykro mi. Strona na którą wszedłeś nie istnieje.</p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
